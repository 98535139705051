/* contact page $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
button {
    border-radius: 50px;
    background: #010606;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-text-decoration: none;
    text-decoration: none;
}

button:hover{
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background:  #01bf71;
    color: #010606;
}



.input1, select {
    width: 100%;
    max-width: 600px;;
    border-radius: 50px;
    padding: 10px 22px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

textarea{
    border-radius: 30px;
    padding: 10px 22px;
    /* width: 100%; */
    /* overflow-x: hidden; */
    resize: none;
    margin-bottom: 10px;
}


input:hover, select:hover, textarea:hover{
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background: #01bf71;
}

form{
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    width: 100;
    
}


/*  tarifs page $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
.h2{
    margin-bottom: 18px;
}


div2{
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-width: 60%;
    margin-top: 15px;
    border: 1px solid #01bf71;
    border-radius: 20px;
   
}














